 
:root{
    --text-color: #343f52;
    --h-color:#343f52;
    --bs-body-font-size: 1rem;
    --bs-primary:#7e0095;
    --box-shadow-lg:0 .25rem 1.75rem rgba(30,34,40,.07);
    --btn-shadow:0 0.25rem 0.75rem rgb(30 34 40 / 15%);
    --translate-y:translateY(-.15rem);
    --font-family: "Manrope",sans-serif;
    --bs-info :#7d46dd;
    --bs-danger:#e2626b;
    --section-bg:#0d6efd0d;
    --h-font-family:'fangsong';
    --bs-success:#198754;

    --bs-outline-primary:#7f009523;
    --bs-outline-info:#7d46dd25;
    --bs-outline-danger:#e2626b1c;
    --bs-outline-success:#1987542f;
    --price-card-shadow:0 0 0 0.05rem rgb(8 60 130 / 6%), 0 0 1.25rem rgb(30 34 40 / 4%);
    --font-size-8rem:0.8rem;
    --font-size-1rem:1.2rem;
}
 
.doc-home-page{
    font-size:var(--bs-body-font-size)!important;
    font-weight: 500!important;
    font-family: var(--font-family)!important;
    color:var(--text-color)!important;

}


.doc-home-page .h1, .doc-home-page .h2, .doc-home-page .h3, .doc-home-page .h4, .doc-home-page .h5, .doc-home-page .h6, .doc-home-page h1, .doc-home-page h2, .doc-home-page h3, .doc-home-page h4, .doc-home-page h5, .doc-home-page h6
{
    color: var(--h-color)!important;
    font-family:var(--h-font-family)!important;

}


/* navbar */
.doc-home-page .w-90{
    width: 90% !important;
}


@media (max-width: 991.98px){
    .doc-home-page .navbar-expand-lg .navbar-collapse .nav-link
    {
        color:white!important;

    }
}

@media (min-width: 992px){
    .doc-home-page .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.doc-home-page .offcanvas-nav-btn {
    border:none;
    background:none;
}
.doc-home-page .navbar-light .navbar-nav .nav-link{
    font-weight: 600;

}

.doc-home-page .nav-link{
    color:var(--bs-primary)!important;
}
.doc-home-page .offcanvas-footer .social a{
    padding:0px 5px;
}
.doc-home-page .offcanvas-footer .social a i{
    font-size: 20px!important;
}

@media (min-width: 992px){
    .doc-home-page .nav-link{
        padding:1.2rem 1rem!important;
    }
}
/* end navbar */
 

.doc-home-page .btn-primary{
    background-color: var(--bs-primary)!important;
    border-color: var(--bs-primary)!important;
}
.doc-home-page .btn{
    transition: all .2s ease-in-out!important;
    line-height: 1.7!important;
    font-weight: 700!important;
    padding:.5rem 1.2rem!important;
    border:none!important;
}
.doc-home-page .btn:not(.btn-play):hover{
    transform: var(--translate-y)!important;
    box-shadow: var(--btn-shadow) !important;
}
/* bg color  */
.doc-home-page .bg-primary{
    background-color: var(--bs-primary)!important;
    color: white!important;
}
.doc-home-page .bg-info{
    background-color: var(--bs-info)!important;
    color: white!important;
}
.doc-home-page .bg-success{
    background-color: var(--bs-success)!important;
    color: white!important;
}
.doc-home-page .bg-danger{
    background-color: var(--bs-danger)!important;
    color: white!important;
}
/* end bg color */


.doc-home-page .text-primary{
    color: var(--bs-primary)!important;
}


.doc-home-page .alert-inner{
    padding: 1rem 0!important;
}

.doc-home-page .badge-lg {
    font-size: .8rem;
    padding: 0.35rem 0.55rem;
}

.doc-home-page .font-weight-600{
    font-weight: 600!important;
    font-size: .8rem;
}



.doc-home-page section::selection{
    background: rgba(226,98,107,.7)!important;
}

.doc-home-page .display-1{
    font-family: "dm serif display"!important;
    font-weight: 400!important;
    word-spacing: normal!important;
    letter-spacing: normal!important;
}

.doc-home-page h1.display-1{
    font-size: 3.5rem!important;
    font-family: fangsong!important;
    font-weight: 600!important;
    line-height: 1.1!important;
}

.doc-home-page .custom-shadow-lg{
    box-shadow: var(--box-shadow-lg) !important;
}
.doc-home-page .rounded{
    border-radius: 0.375rem!important;
    overflow: hidden;
}
.doc-home-page footer .btn.rounded-pill.buy{
    padding: 15px 29px!important; 
}
.doc-home-page .btn.rounded-pill{
    padding: 12px 25px!important;
}
.doc-home-page .mb-n18{
    margin-top: 8rem!important;
}

.doc-home-page .btn-play{
    border-radius: 100%!important;
    overflow: hidden!important;
}

.doc-home-page .btn-play i.bi.bi-play{
    font-size: 30px!important;
}

.doc-home-page .btn-play:hover{
    box-shadow: var(--btn-shadow)!important;
}


.doc-home-page .btn-circle{
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
    -webkit-animation: circle 2s infinite;
        animation: circle 2s infinite;
    background-color: var(--bs-primary);

}
.doc-home-page .btn-circle:after{

        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 80px;
        height: 80px;
        background: var(--bs-primary);
        border-radius: 50%;
        animation: circle-border 1500ms ease-out infinite;

}
 

@keyframes circle {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  }



@keyframes circle-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }

  .doc-home-page p{
      color: var(--text-color);
  }

  .doc-home-page .btn-info{
    background-color: var(--bs-info);
    border-color: var(--bs-info);
    color: white;
}

.doc-home-page .doc-home-page .btn-info:hover{
    background-color: var(--bs-info)!important;
    border-color: var(--bs-info)!important;
    color: white!important;
}
.doc-home-page .btn-success:hover{

    background-color: var(--bs-success)!important;
}


.doc-home-page .btn-danger {
    background-color: var(--bs-danger);
    border-color: var(--bs-danger);
    color: white;
}

.doc-home-page .btn-danger:hover{
    background-color: var(--bs-danger)!important;
    border-color: var(--bs-danger)!important;
    color: white!important;
}

.doc-home-page .section-bg{
    background-color: var(--section-bg);
}

.doc-home-page .btn-outline-primary{
    border: none !important;
    background-color: var(--bs-outline-primary)!important;
    color:var(--bs-primary)!important;

}
.doc-home-page .btn-outline-primary:hover{
    box-shadow: var(--btn-shadow)!important;
    background-color: var(--bs-outline-primary)!important;
    color:var(--bs-primary)!important;
}

.doc-home-page .btn-outline-info{
    border: none;
    color: var(--bs-info)!important;
    background-color: var(--bs-outline-info)!important;
}
.doc-home-page .btn-outline-info:hover{
    box-shadow: var(--btn-shadow)!important;
    background-color: var(--bs-outline-info)!important;
    color: var(--bs-info)!important;
}

.doc-home-page .btn-outline-danger{
    border: none;
    color: var(--bs-danger)!important;
    background-color: var(--bs-outline-danger)!important;
}
.doc-home-page .btn-outline-danger:hover{
    box-shadow: var(--btn-shadow)!important;
    background-color: var(--bs-outline-danger)!important;
    color: var(--bs-danger)!important;

}

.doc-home-page .btn-outline-success{
    border: none;
    color: var(--bs-success)!important;
    background-color: var(--bs-outline-success)!important;
}
.doc-home-page .btn-outline-success:hover{
    box-shadow: var(--btn-shadow)!important;
    background-color: var(--bs-outline-success)!important;
    color: var(--bs-success)!important;

}


.doc-home-page .bg-line-style{
    background: repeating-linear-gradient(-55deg,rgba(255,255,255,0) 0.8px,#7e00954f 1.6px,#7e00954f 3px,rgba(255,255,255,0) 3.8px,rgba(255,255,255,0) 10px);

}


.doc-home-page section .title,footer .title{

    font-weight: 600!important;
    line-height: 1.1!important;
}

.doc-home-page .mr-1{
    margin-right:1rem!important;
}

.doc-home-page .list-unstyled li i.fa.fa-check
{
    background: #e7f2ec;
    border-radius: 50%;
    padding: 5px;
    color:var(--bs-success) ;
}
.doc-home-page .list-unstyled li i.fa.fa-close
{
    background: var(--bs-outline-danger);
    border-radius: 50%;
    padding: 5px;
    color:var(--bs-danger) ;
    width: 26px!important;
    height: 26px!important;
    text-align: center;
}

.doc-home-page .list-unstyled li{
    margin-top: 15px !important;
}

.doc-home-page .typed-cursor{
    color:var(--bs-primary) !important;
    margin-left: 5px!important;
    font-weight: 100!important;
}

.doc-home-page .fs-15{
    font-size: 15px!important;
}

.doc-home-page .fs-1-5{
    font-size: 1.5rem!important;
}



/* badge */

.doc-home-page .badge.badge-outline-primary{
    background-color: var(--bs-outline-primary)!important;
    color: var(--bs-primary)!important;
}
.doc-home-page .badge.badge-outline-info{
    background-color: var(--bs-outline-primary)!important;
    color: var(--bs-primary)!important;
}
.doc-home-page .badge.badge-outline-primary{
    background-color: var(--bs-outline-primary)!important;
    color: var(--bs-primary)!important;
}
.doc-home-page .badge.badge-outline-success{
    background-color: var(--bs-outline-success)!important;
    color: var(--bs-success)!important;
}
.doc-home-page .badge.badge-outline-danger{
    background-color: var(--bs-outline-danger)!important;
    color: var(--bs-danger)!important;
}

/* end badge  */

/*key features design */
.doc-home-page section.key-features .card{
    border: none !important;
    box-shadow: var(--box-shadow-lg) !important;
    border-radius: 10px!important;
    transition: .3s;

}
.doc-home-page section.key-features .card:hover{
    transform: translateY(-0.4rem);
}
.doc-home-page section.key-features a{
    text-decoration: none!important;
    color: unset!important;
}
.doc-home-page section.key-features .w-30{
    width: 30%;
}




.doc-home-page .badge{
    border-radius: 20px!important;
}

.doc-home-page section.bg-light{
    --bs-bg-opacity: 0.5;
    background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
}

.doc-home-page section.key-features h4,section.how-work-it h4{
    font-weight: 600!important;
}
.doc-home-page section.key-features .card .key-profile,section.how-work-it .card .key-profile{
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    font-weight: 700!important;
    line-height: 1.7!important;
    letter-spacing: -.01rem!important;
    border-radius: 100%!important;
    height: 4rem!important;
    width: 4rem!important;
    text-transform: uppercase!important;
}
.doc-home-page section.how-work-it .card .key-profile{
    width: 2rem!important;
    height: 2rem!important;
}
.doc-home-page section.how-work-it .card .d-flex h4{
    margin-bottom: 0px;
}

.doc-home-page .mr-15px{
    margin-right:15px!important;
}




/* end key features design */

 

.doc-home-page .fs{
    font-size: 2rem!important;
}

.doc-home-page section.how-work-it .card{
    border: none!important;
    box-shadow: var(--box-shadow-lg)!important;
    border-radius: 10px!important;
}


.doc-home-page section.pricing-table .card, section.customer-feedback .card
{
    border:none!important;
    border-radius: 10px!important;
    box-shadow: var(--box-shadow-lg)!important;
}
.doc-home-page section.pricing-table .card .card-body
{
    margin: auto!important;
}
.doc-home-page section.pricing-table .card .currency{
    display: inline-block;
    font-size: 1.3rem;
    font-weight: 400;
    padding-right: 0.2rem;
    padding-top: 0.4rem;
    font-family:'FontAwesome';
}

.doc-home-page section.pricing-table .card .price{
    font-size: 3rem!important;
    font-weight: 100!important;
}

.doc-home-page section.pricing-table .card .duration{
    margin-top: 30px!important;
    margin-left: 5px;
}

.doc-home-page section.pricing-table .card h4.font-weight-bold{
    font-weight: 600!important;
}


.doc-home-page section.pricing-table .card  ul li{
    margin-top:10px!important;
}


@media screen and (min-width:992px) {

    .doc-home-page .mt-minus-20{
        margin-top: -20px!important;
    }
}


.doc-home-page .bg-dots{
    background-image: radial-gradient(circle, #0d6efd1c 20%, transparent 10%), radial-gradient(circle, #0d6efd1c 20%, transparent 10%);
    background-size: 5px 5px;
    background-position: 0 0, 50px 50px;
}

.doc-home-page blockquote::before{
    content: "\201c";
    color: #aab0bc;
    opacity: .3;
    font-size: 6.5rem;
    font-weight: 400;
    top: 0;
    left: 50%;
    transform: translateX(-52%);
    line-height: 1;
    z-index: 1;
}

.doc-home-page .customer-rate{
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 0.8rem;
    font-size: 1.3rem;
    line-height: 1;
}


.doc-home-page .customer-rate:before {
    color: rgba(38,43,50,.1);
}

.doc-home-page .customer-rate:after, .customer-rate:before {
    display: inline-block;
    font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,Helvetica,Arial,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol;
    font-style: normal;
    font-weight: 400;
    position: absolute;
    top: 0;
    left: 0;
    color: #fcc032;
    content: "\2605\2605\2605\2605\2605";
    overflow: hidden;
}




/* responsive  */

@media screen and (max-width: 991px) {

    .doc-home-page{
        font-size:var(--font-size-1rem)!important;
    }

    .doc-home-page .btn,.doc-home-page .btn.rounded-pill{
        padding:0.3rem 1rem!important;
        font-size: var(--font-size-1rem)!important;
    }

    .doc-home-page section.key-features .card .key-profile, section.how-work-it .card .key-profile{
        width: 3rem!important;
        height: 3rem!important;
    }
    .doc-home-page .swiper .card.m-5{
        margin: 2px!important;
        margin-bottom: 5rem!important;
    }

    .doc-home-page.swiper .card .blockquote-box{
        padding-left:0.5rem!important;
        padding-right:0.5rem!important;
    }

    .doc-home-page blockquote::before {
        font-size: 4rem!important;
    }

    .doc-home-page .py-5{
        padding-top:2rem!important;
        padding-bottom: 2rem!important;
    }

    .doc-home-page section.customer-feedback .card {
        border: none!important;
        border-radius: 10px!important;
        box-shadow: var(--price-card-shadow)!important;
    }


    .doc-home-page .offcanvas.offcanvas-nav{
        width: 60%!important;
    }

    .doc-home-page h1.display-1{
        font-size: 3rem!important;
    }

    .doc-home-page .navbar.navbar-expand-lg.center-nav{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .doc-home-page .navbar.navbar-expand-lg.center-nav .container.flex-lg-row.flex-nowrap.align-items-center{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }
    .doc-home-page h1.title.mb-5{
        margin-bottom: 2rem!important;
        margin-top: 2rem!important;
    }

    .doc-home-page .display-6{
        font-size: calc(1.375rem + 0.5vw)!important;
    }

    .doc-home-page .key-features .container.pb-5.mb-5{
        margin-bottom: 0px!important;
    }

    .doc-home-page .fs-1-5 {
        font-size: calc(1rem + 0.3vw)!important;
        margin-top: 2rem!important;
    }

    .doc-home-page section.pricing-table .card.p-5{
        padding:3rem 1rem!important;
    }
    .doc-home-page section.pricing-table .card .card-body{
        margin:unset!important;
    }
    .doc-home-page .pricing-table .btn.rounded-pill{
        padding:15px 29px!important;
    }
}

/* end responsive */
.doc-home-page .font-weight-bold{
    font-weight: bold;
}
.doc-home-page .font-size-25{
    font-size: 25px;
}

.doc-home-page .dashboard-section{ 
    background-attachment: fixed;
    background-size: contain;
    background-position: center;
}
.doc-home-page .dashboard-section .dashboard-overlay{
    background: rgb(0 0 0 / 61%);
}
.doc-home-page .dashboard-section .dashboard-content p{
  color: white!important;
}

.doc-home-page .Industries i{
    font-size: 60px!important;
    color: var(--bs-primary);
}

.doc-home-page .text-2rem{
    font-size: 2rem!important;
}

 
.doc-home-page .nav-item a.nav-link:hover{
    color:#0d6efd!important;
} 
.doc-home-page .nav-item a.nav-link:hover,
.doc-home-page .nav-item a.nav-link{
    color: var(--bs-primary)!important;
}

.doc-home-page footer .btn.rounded-pill.buy,.doc-home-page .nav-item .btn.nav-link{ 
    color: white!important;
}
 


.doc-home-page h3.brand-title,
.doc-home-page .nav-item .btn.nav-link:hover{
    color: white!important;
}