body{
  box-sizing: border-box;
  scroll-behavior:smooth;
  -webkit-font-smoothing: antialiased;
  color: #34495e !important;
  font-family: system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,Helvetica,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
  font-size: 18px !important;
  letter-spacing: 0;
  margin: 0;
  overflow-x: hidden;
}
.doc-page{
  min-height: 100vh;
}
.pagination-title{
  color: #34495e;
  font-size: 1.2rem;
  font-weight: 600;
}

.pagination-title:hover{
  text-decoration: underline;
  text-decoration-color: #34495e;
}

::selection {
    color: white;
    background: #5254cf;
  }
a.text-primary,h5.text-primary,b.text-primary
{
    color:#7e0095!important;
}
.active-has
{
    color:#7e0095!important;
}
.btn-primary{
    background-color:#7e0095!important;
    border-color: #7e0095!important;
    border-radius: 30px!important;
}

 .right-content-box img{
   border: 1px solid #dee2e6!important
 }


 .px-5.right-content-box{
    padding-left: 5rem!important;
    padding-right: 5rem!important;
}

@media screen and (max-width:768px) {
    .px-5.right-content-box{
        padding-left: 1rem!important;
        padding-right: 1rem!important;
    }
}


.right-content-box{ 
  margin-top: 5%!important;
}
.right-side{
  width: 70%!important;
}
.right-menu{
  width: 30%!important;
}

nav a{
  color:#7e0095!important;
}
.docs-menus a{
  margin-left: 0px!important;
  padding-left: 0px!important;
}
.right-content-box-width{
  width: 70%!important;
}
.right-menu li{
  list-style: none!important;
}
.breadcrumb-item.active{
  color: #7e0095!important;
}
.breadcrumb-item+.breadcrumb-item::before{
  content: ">"!important;
  padding-left: 5px;
  font-family: monospace!important;
}
.right-menu{
  position: relative !important;
}
.right-menu ul{
  position: fixed !important;
}
.right-menu ul li a{
   font-size: 15px;
   color: unset;
}


.d-flex.justify-content-between.border-top.mt-5 .text-start{
  width: 50%!important;
}
.d-flex.justify-content-between.border-top.mt-5 .text-end{
  width: 50%!important;
}
.d-flex.justify-content-between.border-top.mt-5 .text-start>a,
.d-flex.justify-content-between.border-top.mt-5 .text-end>a{
  display: block;
  padding:10px;
  border: 1px solid #a5a5a575; 
  border-radius: 5px;
  transition: .5s;
  text-decoration: none!important;
}

.d-flex.justify-content-between.border-top.mt-5 .text-start{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  margin-right: 10px !important;
 
}
.d-flex.justify-content-between.border-top.mt-5 .text-end{
  margin-top: 30px !important;
  margin-bottom: 30px !important;
  margin-left: 10px !important;
 
}
 
.d-flex.justify-content-between.border-top.mt-5 .text-start>a:hover,
.d-flex.justify-content-between.border-top.mt-5 .text-end>a:hover{
  text-decoration: none!important;
  border-color: #7e0095!important;
}

.d-flex.justify-content-between.border-top.mt-5 .text-start .pagination-title,
.d-flex.justify-content-between.border-top.mt-5 .text-end .pagination-title{
   color:#7e0095!important;
}

.d-flex.justify-content-between.border-top.mt-5 .text-start .pagination-title:hover,
.d-flex.justify-content-between.border-top.mt-5 .text-end .pagination-title:hover{
  text-decoration: none!important ;
}

#sidebarMenu{
  align-items: normal!important;
}

@media screen and (max-width:991px) {
  .right-content-box-width{
    width:100%!important;
  }
}


#documentation-list .card{
  border: none;
  box-shadow: 0px 0px 5px #0000003d;
  border-radius: 10px;
}

.navbar-toggler-icon{
  background-image:url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e')!important;
  
}

.menu-btn svg.grid-icon,
.docs-btn svg.grid-icon{
  font-size: 25px!important;
  margin-left: 0px!important;
}
@media screen and (max-width:991px) {
    .project-thumbnail{
      width: 100%!important;
    }
}

.doc-home-page span.border-redius-70px{
  border-radius: 70px!important;
}


/* WeERP design */
 

.weerp-navigation a.text-primary,h5.text-primary,b.text-primary
{
    color:#4e4c9f!important;
}
.weerp-navigation nav .breadcrumb-item a
{
    color:#4e4c9f!important;
}
.weerp-navigation .active-has
{
    color:#4e4c9f!important;
}
.weerp-navigation .btn-primary{
    background-color:#4e4c9f!important;
    border-color: #4e4c9f!important;
}
 
.weerp-navigation .breadcrumb-item.active{
  color: #4e4c9f!important;
}
.weerp-navigation .d-flex.justify-content-between.border-top.mt-5 .text-start>a:hover,
.weerp-navigation .d-flex.justify-content-between.border-top.mt-5 .text-end>a:hover{
  border-color: #4e4c9f!important;
}

.weerp-navigation .d-flex.justify-content-between.border-top.mt-5 .text-start .pagination-title,
.weerp-navigation .d-flex.justify-content-between.border-top.mt-5 .text-end .pagination-title{
   color:#4e4c9f!important;
}
 
/* end WeERP desing */
 




/* wemover design */
 

.wemover-navigation a.text-primary,h5.text-primary,b.text-primary
{
    color:#e33c3c!important;
}
.wemover-navigation nav .breadcrumb-item a
{
    color:#e33c3c!important;
}
.wemover-navigation .active-has
{
    color:#e33c3c!important;
}
.wemover-navigation .btn-primary{
    background-color:#e33c3c!important;
    border-color: #e33c3c!important;
}
 
.wemover-navigation .breadcrumb-item.active{
  color: #e33c3c!important;
}
.wemover-navigation .d-flex.justify-content-between.border-top.mt-5 .text-start>a:hover,
.wemover-navigation .d-flex.justify-content-between.border-top.mt-5 .text-end>a:hover{
  border-color: #e33c3c!important;
}

.wemover-navigation .d-flex.justify-content-between.border-top.mt-5 .text-start .pagination-title,
.wemover-navigation .d-flex.justify-content-between.border-top.mt-5 .text-end .pagination-title{
   color:#e33c3c!important;
}
.only-mobile-frame img{
  height: 500px;
  object-fit: contain;
  width: unset!important;
}
/* end wemover desing */
 