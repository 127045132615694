pre{
  padding: 3em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;

    background-color: rgb(52 52 52);
    color:white!important;
    border-radius: 10px;
    padding: 20px;
    background-size: 100%;
    display: block;
    line-height: 25px;
    margin-bottom: 10 px;
    overflow: visible;
    overflow-y: hidden;
    border: 1px solid black;
    display: inline-block;
    white-space: break-spaces;
  }

  /* code{
    display: inline-block;
  } */
