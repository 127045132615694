.left-side {
    width: 300px !important;
    min-height: 100vh;
}

.right-side {
    width: calc(100vw - 300px);
    position: relative;
    min-height: 100vh;
    overflow-y: scroll;
}

.menu-text {
    font-size: 15px;
}

.sub-menu {
    list-style-type: none;
    padding-left: 0px;
}

.sub-menu-text {
    font-size: 18px !important;
    color: #364149 !important;
}

.sub-menu li a:hover {
    text-decoration: underline !important;
    text-decoration-color: #364149;
}

#sidebarMenu {
    width: 90%;
    margin-left: 15px;
    overflow-y: scroll;
    height: 100vh;
    overflow-x: none;
    padding-bottom: 150px !important;
}

.sidebar {
    width: 300px;
}

input:focus {
    outline: 0px !important;
}

/* hide scrollbar but allow scrolling */
#sidebarMenu {
    overflow: none;
    scrollbar-width: none;
    /* for Firefox */
    overflow-y: scroll;
}

#sidebarMenu::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.menu-item {
    list-style-type: none;
    margin-bottom: 15px;
}

.menu-item a {
    text-decoration: none;
}

.menu-item p {
    font-weight: 600;
    color: #364149 !important;
    margin: 0;
}

.nav-item {
    margin-bottom: 0;
}

.nav-item a {
    padding-bottom: 0;

}

.active-hash {
    color: #7e0095 !important;
    font-weight: 600;
    border-right: 2px solid #7e0095;
    width: 100%;
}

.nav-item a.nav-link {
    transition: 0.3s;
}

.nav-item a.nav-link:hover, .nav-item a.nav-link.active-hash {
    text-decoration: none !important;
    color: #7e0095 !important;
    /* margin-left: 5px!important; */

}

.main-nav-link {
    text-decoration: none !important;
}

a {
    text-decoration: none !important;
}

.main-nav-link.active>p {
    color: #7e0095 !important
}

.sub-menu .nav-link {
    padding-left: 0px !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}




.menu-btn {
    border: none;
    background: none !important;
    margin: 0px;
    padding: 0px;
    margin-top: 0px !important;


}
.docs-btn {
    border: none;
    background: none !important;
    margin: 0px;
    padding: 0px;
    margin-top: 0px !important; 
}

.menu-btn svg {
    font-size: 50px !important;
}


.close-btn-box {
    position: fixed;
    top: 0px;
    background-color: white;
    width: 100%;
    height: 50px !important;
}

.right-side {
    overflow: hidden;
}

/* .close-btn-box .docs-menus{
    margin-left: 20%;
} */
.close-btn-box .docs-menus a {
    font-size: 1rem !important;
}

.close-btn-box .menu-btn {
    display: none !important;
}

.close-nav-btn {
    display: none !important;
}


@media screen and (max-width: 992px) {
    .close-btn-box .docs-menus {
        margin-left: 15%;
    }

    .left-side {
        display: none;
        position: absolute !important;
        z-index: 1 !important;
        background-color: white !important;
    }

    .is-active {
        display: block !important;
    }

    .close-btn-box .menu-btn {
        display: inline-block !important;
    }

    .doc-page {
        margin-top: 15px !important;
    }

    .fixed-top.sidebar h4 {
        margin-bottom: 0px !important;
    }

    .fixed-top.sidebar {
        background-color: white;
    }

    .close-nav-btn {
        display: inline-block !important;
        background-color: none !important;
        margin-left: 50%;
    }

    .close-nav-btn .menu-btn svg {
        font-size: 30px !important;
    }


}

.pl-0 {
    padding-left: 0px !important;
}

@media screen and (max-width: 450px) {
    .close-btn-box.close-nav-btn {
        margin-left: 65% !important;
    }
}


.accordion-button {
    border: none !important;
    padding: 0px !important;
    background-color: none !important;
    box-shadow: none !important;
}

.accordion-button.collapsed,
.accordion-button:not(.collapsed) {
    background-color: unset !important;
}

.accordion-button:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.accordion-item {
    border: none !important;
}

.accordion-body {
    padding: 0px !important;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
}


.accordion-button::after {
    transform: rotate(-90deg) !important;
}

.accordion-collapse.collapse.shows {
    display: block !important;
}

.afterignore h5::after {
    display: none !important;
}

.menu-item>a>h5:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    content: "";
    flex-shrink: 0;
    height: 1.25rem;
    margin-left: auto;
    position: absolute;
    right: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    width: 1.25rem;
}

.menu-item>a[aria-expanded=true]>h5:after {
    -webkit-transform: rotate(0deg) !important;
    transform: rotate(0deg) !important;
}

.menu-item>a>h5 {
    color: #364149 !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
}

#sidebarMenu {
    padding: 0 !important;
}

.sub-menu {
    margin-left: 10px !important;
}

li .sub-menu .nav-link, li.menu-item h5 {
    font-size: 1rem !important;
}

.menu-item>a[aria-expanded=true]>h5 {
    color: #7e0095 !important;
}

.docs-menus a {
    color: #364149 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.docs-menus a.text-primary {
    color: #7e0095 !important;
}

.afterignore .text-primary {
    color: #7e0095 !important;
}


.menu-close{
    display: none!important;
}
@media screen and (max-width:991px) {
    .menu-close {
        display: block!important;
    }
}


/* wemover design */


.wemover-navigation .active-hash {
    color: #e33c3c !important; 
    border-right: 2px solid #e33c3c; 
}
 
.wemover-navigation .nav-item a.nav-link:hover,.wemover-navigation .nav-item a.nav-link.active-hash { 
    color: #e33c3c !important; 

}


.wemover-navigation .main-nav-link.active>p {
    color: #e33c3c !important
}

.wemover-navigation .menu-item>a[aria-expanded=true]>h5 {
    color: #e33c3c !important;
}
 
.wemover-navigation .docs-menus a.text-primary {
    color: #e33c3c !important;
}

.wemover-navigation .afterignore .text-primary {
    color: #e33c3c !important;
}


/* end wemover design */